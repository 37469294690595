const fullKanaRegExp = new RegExp(/^([\u30A1-\u30FC]+)$/)
const validatePhoneRegExp = /^0+([0-9])*$/
const localPartRegExp = new RegExp(/^([A-Za-z0-9.!#$%&'*-+/=?^_`{|}~-])+$/)
const domainPartRegExp = new RegExp(/^([A-Za-z0-9?:.-])+$/)
const holderNameRegExp = new RegExp(/^[a-zA-Z0-9\-.,/ ]+$/)

/**
 * 全角カタカナと文字列の長さをチェックします。
 * @param {String} value
 * @param {String} length
 */
export const fullKana = (value, length) => {
  if (!value || !length) {
    return false
  }

  return new Promise((resolve) => {
    resolve({
      valid: value.length <= (0 + length) && fullKanaRegExp.test(value)
    })
  })
}

/**
 * 0始まりの数字のみの電話番号かをチェックします。
 * 6桁未満の電話番号かをチェックします。
 * @param {String} value
 */
export const validatePhone = (value) => {
  return new Promise((resolve) => {
    resolve({
      valid: value.match(validatePhoneRegExp) && value.length > 5
    })
  })
}

/**
 * 半角数字前提の文字列の長さをチェックします。
 * @param {String} value
 * @param {String} length
 */
export const minlengthNumeric = (value, length) => {
  if (!value || !length) {
    return false
  }
  return new Promise((resolve) => {
    resolve({
      valid: value.length > (0 + length - 1)
    })
  })
}

/**
 * メールアドレスに以下の文字以外が存在するかチェックします。
 * ローカルパート：a-z A-Z 0-9 .!#$%&'*+\/=?^_`{|}~-
 * ドメインパート：a-z A-Z 0-9 ?:\.
 * @param {String} value メールアドレス
 * @see emailの詳細(loalPart,domailPart)については以下を参照(vee-validateのemailと関連)
 * https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
 */
export const mailAddress = (value) => {
  const splitParts = value.split('@')
  const countAtSign = splitParts.length
  let localPart = ''
  let domainPart = ''
  if (countAtSign === 2) {
    localPart = splitParts[0]
    domainPart = splitParts[1]
  }
  return new Promise((resolve) => {
    resolve({
      valid: countAtSign === 2 && localPart.match(localPartRegExp) && domainPart.match(domainPartRegExp)
    })
  })
}

/**
 * カード名義に以下の文字以外が存在するかチェックします。
 * a-z A-Z 0-9 - . , /
 * @param {String} value カード名義
 * @see vee-validateの詳細(正規表現カンマ利用不可)については以下を参照
 * https://github.com/baianat/vee-validate/issues/223
 */
export const holderName = (value) => {
  return new Promise((resolve) => {
    resolve({
      valid: value.match(holderNameRegExp)
    })
  })
}

/**
 * 文字列にサロゲートペアの文字が存在するかチェックします。
 * 𪛀 𠀀 𪛉 絵文字 (Emoticons)😱
 * @param {String} value 文字列
 */
export const surrogatePair = (value) => {
  return new Promise((resolve) => {
    const charValue = value.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]|[\s\S]|^$/g).filter(item => Boolean(item))
    resolve({
      valid: !charValue.some(item => item.length > 1)
    })
  })
}
