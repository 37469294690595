export const state = () => ({
  invoice: null,
  access: false,
  toDate: null,
  fromDate: null
})

export const getters = {
  invoice: (state) => {
    return state.invoice
  },
  canAccess: (state) => {
    return state.access
  }
}

export const mutations = {
  setApiParameters(state, selectedDate) {
    state.toDate = this.$moment(selectedDate)
    // 期間が1年になるように調整
    state.fromDate = this.$moment(selectedDate).subtract({ years: 1 }).add(1, 'days')
  },
  setInvoice(state, invoice) {
    state.invoice = invoice
    state.access = true
  },
  clear(state) {
    state.invoice = null
    state.access = false
  }
}

export const actions = {
  async fetchBillingHistories({ state, commit }, selectedDate) {
    commit('setApiParameters', selectedDate)
    const params = {
      fromdate: state.fromDate.format('YYYYMMDD'),
      todate: state.toDate.format('YYYYMMDD')
    }
    const response = await this.$httpWrapper.$get('/contract/billinghistory', { params })
    return response
  }

}
