export const state = () => ({
  loading: false
})

export const mutations = {
  on(state) {
    state.loading = true
  },
  off(state) {
    state.loading = false
  }
}
