<template>
  <div class="l-page">
    <l-header />
    <div class="l-container">
      <nuxt />
    </div>
    <l-footer />
  </div>
</template>

<script>
import lFooter from '~/components/layouts/footer.vue'
import lHeader from '~/components/layouts/headerGuest.vue'

export default {
  name: 'ErrorPageLayout',
  components: {
    lFooter,
    lHeader
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/foundation/base.scss';

.l-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  word-break: break-all;
}

.l-container {
  flex-grow: 1;
  flex-shrink: 0;
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 15px;
  /*max-width: 640px;*/
  @include desktop() {
    /*max-width: 800px;*/
    /*padding: 0 0 158px 0;*/
    width: 89%;
  }
}
</style>
