import { sortColonyGhosts } from '~/service/sortObject'

export const state = () => ({
  wordJoiner: null,
  nestLabel: null,
  emptyDataLabel: null,
  repairQuestions: null
})

export const mutations = {
  setState(state) {
    state.wordJoiner = this.app.i18n.t('contactMember.wordJoiner')
    state.nestLabel = this.app.i18n.t('common.nest')
    state.emptyDataLabel = this.app.i18n.t('common.emptyDataLabel')
  },
  setRepairQuestions(state, data) {
    state.repairQuestions = data
  }
}
export const getters = {
  getRepairQuestions(state) {
    return state.repairQuestions
  }
}

export const actions = {
  async fetchColonyHistory({ dispatch, commit }, colonyId) {
    commit('setState')
    // お問い合わせ履歴詳細APIから取得したcolonyIdをもとにコロニー情報を取得する。
    // パラメータのcolonyIdはGxCloudから取得したものなのか、zuoraのサブスクリプションからなのか不明なので
    // 以下の順序でcolonyIdに該当するデータを取得
    // 1. GxCloud
    // 2. サブスクライバー
    // 上記いずれかのあるほうのレスポンスで画面表示を行う。
    // エラー発生、該当データがない場合は新規お問い合わせで選択したLOVOTを非表示にするので
    // エラーハンドリングは行わない。
    const aggregatedColonies = []
    try {
      // GxCloud
      await dispatch('getColonyDetail', {
        useSubscriber: false,
        colonyId,
        aggregatedColonies
      })
    } catch (error) {
      // noop エラーは無視する。
    }
    if (aggregatedColonies.length === 0) {
      try {
        // サブスクライバー
        await dispatch('getColonyDetail', {
          useSubscriber: true,
          colonyId,
          aggregatedColonies
        })
      } catch (error) {
        // noop エラーは無視する。
      }
    }

    return aggregatedColonies
  },
  async fetchColonies({ dispatch, commit }) {
    commit('setState')
    // GxCloudとZuoraのサブスクリプションからコロニー情報を取得する。
    // GxCloudとサブスクリプションのレスポンスからコロニーIDが同じのものは１つに集約する。
    let subscList = []
    let colonies = []
    try {
      const gxcColoniesResponse = await this.$httpWrapper.$get('/colonies')
      if (gxcColoniesResponse.colonies) {
        colonies = gxcColoniesResponse.colonies
      }
    } catch (error) {
      const { response } = error
      if (response) {
        // 非会員のお問い合わせ(登録済みのこちらリンク)から遷移してきた時、
        // 顧客ポータルのアカウントがない場合
        if (response.status === 403) {
          return []
        }
      }
      // 上記以外はエラーが発生しても処理を継続
    }
    try {
      // session APIでエラーが発生した場合、サブスクライバー(hasZuoraAccount:true)のはずなのに、
      // サブスクライバーでないユーザーとして扱われるため、サブスクライバーのみsubscriptionsを呼び出すことはしない。
      const subscriptionsResponse = await this.$httpWrapper.$get('/subscriptions')
      subscList = subscriptionsResponse.subscriptions
    } catch (error) {
      // サブスクライバーのときでもAPI失敗する場合はありうるので
      // (zuoraアカウントは持っているけど有効な契約がない場合)、その際はサブスクライバー用のコロニーは除外
    }
    if (subscList.length < 1 && colonies.length < 1) {
      return []
    }

    // コロニー情報を集約する
    const aggregateColonies = await dispatch('aggregateColonies', { gxcColonies: colonies, subscList })
    // コロニーIDの昇順
    aggregateColonies.sort((a, b) => {
      const c1 = a.colonyId.toLowerCase()
      const c2 = b.colonyId.toLowerCase()
      if (c1 < c2) {
        return -1
      } else if (c1 > c2) {
        return 1
      } else {
        return 0
      }
    })
    return aggregateColonies
  },
  async aggregateColonies({ dispatch }, { gxcColonies, subscList }) {
    const aggregatedColonies = []
    // GxCloudのコロニーリストを全て表示対象に追加
    const dispatchColonyDetails = []
    gxcColonies.forEach((colony) => {
      const colonyInfo = colony.colony
      const role = colony.role
      const colonyid = colonyInfo.colony_id ? colonyInfo.colony_id.trim() : ''
      // GxCから取得したcoloniesのコロニーIDでコロニー詳細を取得
      dispatchColonyDetails.push(dispatch('getColonyDetail', { useSubscriber: false, colonyId: colonyid, role, aggregatedColonies }))
    })
    await Promise.all(dispatchColonyDetails)

    const now = this.$moment()
    // ZuoraのsubscriptionsのコロニーIDがGxCにない場合は、
    // subscriptionsのコロニーIDで詳細情報を取得して表示対象に追加
    for (const subsc of subscList) {
      let hasRecurringType = false
      if (subsc.status !== 'Active' || subsc.colony_id__c == null) continue

      for (const planEntry of subsc.ratePlans) {
        // 月額支払いが存在するか
        if (planEntry.ratePlanCharges.some(charge =>
          charge.type === 'Recurring' && this.$moment(charge.effectiveEndDate).isAfter(now, 'day')
        )) {
          hasRecurringType = true
          break
        }
      }
      if (hasRecurringType) {
        await dispatch('addSubscriptionColonies', { subsc, gxcColonies, aggregatedColonies })
      }
    }
    return aggregatedColonies
  },
  async addSubscriptionColonies({ dispatch }, { subsc, gxcColonies, aggregatedColonies }) {
    // GxCからのコロニーリスト(ログインユーザーがオーナーであるコロニーのみ)にZuoraから取得したコロニーIDが存在しないものだけを抽出(集約)
    const matchedColony = gxcColonies.some(colony => colony.colony.colony_id === subsc.colony_id__c)

    if (matchedColony) {
      // aggregatedColoniesを新しい配列にして、一致するcolony_idの要素のroleを'bu'に更新
      const index = aggregatedColonies.findIndex(colony => colony.colonyId === subsc.colony_id__c)

      if (index !== -1) {
        const [removedItem] = aggregatedColonies.splice(index, 1)
        const updatedItem = { ...removedItem, role: 'bu' }
        aggregatedColonies.push(updatedItem)
      }
    } else {
      const colonyid = subsc.colony_id__c ? subsc.colony_id__c.trim() : ''
      await dispatch('getColonyDetail', { useSubscriber: true, colonyId: colonyid, role: 'bu', aggregatedColonies })
    }
  },
  async getColonyDetail({ state }, { useSubscriber, colonyId, role, aggregatedColonies }) {
    let colonyDetailResponse
    try {
      if (useSubscriber) {
        colonyDetailResponse = await this.$httpWrapper.$get(`/subscriber/colonies/${colonyId}`)
      } else {
        colonyDetailResponse = await this.$httpWrapper.$get(`/colonies/${colonyId}`)
      }
    } catch (error) {
      // 複数コロニーIDの中で１つでも取得に成功するものは画面表示するため、
      // エラーが発生しても処理を継続させる。
    }
    // LOVOT情報ソート
    if (colonyDetailResponse) {
      sortColonyGhosts(colonyDetailResponse)
      const titles = []
      const items = []
      colonyDetailResponse.colony.ghosts.forEach((ghost) => {
        createAggregatedColony(ghost, titles, items, state.nestLabel, state.emptyDataLabel)
      })
      const isRental = colonyDetailResponse.subscription && colonyDetailResponse.subscription.plan === 'RENTAL'
      const suid = colonyDetailResponse.subscription?.subscriber_uid
      moveNest(items)
      aggregatedColonies.push({
        colonyId: colonyDetailResponse.colony.colony_id,
        title: titles.join(state.wordJoiner),
        items,
        isRental,
        suid,
        role
      })
    }
  }
}

const createAggregatedColony = (ghost, titles, items, nestLabel, emptyDataLabel) => {
  const isLovot = ghost.device_type === 'lovot'
  items.push({
    text: isLovot ? ghost.name : nestLabel,
    id: ghost.number ? ghost.number : emptyDataLabel,
    ghostId: ghost.ghost_id,
    deviceId: ghost.device_id,
    model: ghost.model,
    version: ghost.version,
    isLovot
  })
  if (isLovot) {
    // 名前なし且つシリアルナンバー無しの場合には、？を表示
    titles.push(ghost.name ? ghost.name : ghost.number ? ghost.number : emptyDataLabel)
  }
}

const moveNest = (items) => {
  // ネストはリストの最後に移動させる
  let nestIndex = -1
  items.some((item) => {
    nestIndex++
    if (!item.isLovot) {
      return true
    }
  })
  if (items.length - 1 !== nestIndex) {
    const nest = items[nestIndex]
    items.splice(nestIndex, 1)
    items.push(nest)
  }
}
