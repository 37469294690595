<template>
  <div class="l-page">
    <l-header />
    <transition name="fade">
      <ribon v-if="visible" />
    </transition>
    <div class="l-container">
      <nav class="l-side">
        <l-sidemenu />
      </nav>
      <main class="l-main">
        <nuxt />
      </main>
    </div>
    <l-footer />
    <transition name="fade">
      <app-loading v-if="isLoading" />
    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import lFooter from '~/components/layouts/footer.vue'
import lHeader from '~/components/layouts/header.vue'
import lSidemenu from '~/components/layouts/sideMenu.vue'
import Ribon from '~/components/object/navi/Ribon.vue'
import AppLoading from '~/components/object/navi/Loading.vue'

export default {
  name: 'DoubleLayout',
  components: {
    lFooter,
    lHeader,
    lSidemenu,
    Ribon,
    AppLoading
  },
  computed: {
    ...mapState('notify', ['visible']),
    ...mapGetters('common', ['isLoading'])
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/foundation/base.scss';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.l-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  word-break: break-all;
}

.l-container {
  flex-grow: 1;
  flex-shrink: 0;
  padding-bottom: 100px;
  padding-top: $headerHeightSP;
  @include desktop() {
    padding-top: $headerHeightPC;
    padding-bottom: 200px;
    display: flex;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
  }
}

.l-side {
  display: none;
  top: $headerHeightSP;
  width: 200px;
  flex-shrink: 0;
  display: none;
  padding-left: 10px;
  @include desktop() {
    display: block;
    top: $headerHeightPC;
    margin-right: 40px;
  }
}

.l-main {
  width: 100%;
  max-width: 640px;
  padding: 0 16px;
  margin: 0 auto;
  overflow: hidden;
  @include desktop() {
    max-width: unset;
    padding: 0;
  }
}
</style>
