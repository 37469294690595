<template>
  <ul class="l-sideMenu">
    <li class="l-sideMenu__list-item">
      <router-link v-if="$route.path === '/'" to="/" class="pl-2">
        {{ sideMenu.home }}
      </router-link>
      <router-link v-else to="/mypage/lovot" class="pl-2">
        {{ sideMenu.home }}
      </router-link>
    </li>
    <li class="l-sideMenu__list-item --external">
      <a href="https://help.lovot.life/category/information/" target="_blank" class="pl-2">
        <span class="c-icon-external">{{ sideMenu.news }}</span>
      </a>
    </li>
    <li class="l-sideMenu__list-item">
      <div v-if="isOwner">
        <router-link to="/mypage/hospital" class="pl-2">
          {{ sideMenu.hospital }}
        </router-link>
      </div>
    </li>
    <li class="l-sideMenu__list-item">
      <div v-if="hasZuoraAccount">
        <router-link to="/mypage/contract" class="pl-2">
          {{ sideMenu.contractPayment }}
        </router-link>
        <div class="l-sideMenu_child mt-3">
          <router-link to="/mypage/contract" class="mb-2" exact-active-class="l-sideMenu_child_active">
            {{ sideMenu.contract }}
          </router-link>
          <router-link to="/mypage/contract/billing_address" class="mb-2" exact-active-class="l-sideMenu_child_active">
            {{ sideMenu.billingAddress }}
          </router-link>
          <router-link to="/mypage/contract/creditcard" class="mb-2" exact-active-class="l-sideMenu_child_active">
            {{ sideMenu.creditcard }}
          </router-link>
          <router-link to="/mypage/contract/billing" class="mb-2" exact-active-class="l-sideMenu_child_active">
            {{ sideMenu.billing }}
          </router-link>
        </div>
      </div>
    </li>
    <li class="l-sideMenu__list-item">
      <router-link to="/mypage/contact" class="pl-2">
        {{ sideMenu.contactUs }}
      </router-link>
      <div class="l-sideMenu_child mt-3">
        <router-link to="/mypage/contact" class="mb-2" :class="{'l-sideMenu_child_active': isContactMemberRoute, 'u-gray': !isContactMemberRoute}" exact>
          {{ sideMenu.contact }}
        </router-link>
        <router-link to="/mypage/contact/history" class="mb-2" exact-active-class="l-sideMenu_child_active">
          {{ sideMenu.history }}
        </router-link>
      </div>
    </li>
    <div v-if="isOwner">
      <li class="l-sideMenu__list-item">
        <router-link to="/mypage/official-supporters">
          {{ sideMenu.officialSupporters }}
        </router-link>
      </li>
    </div>
    <li class="l-sideMenu__list-item --external">
      <a href="https://lovot.life/" target="_blank">
        {{ sideMenu.webSite }}
      </a>
    </li>
    <li class="l-sideMenu__list-item --external">
      <a :href="env.gx_ec_portal_url" target="_blank">
        {{ sideMenu.webStore }}
      </a>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SideMenu',
  data() {
    return {
      sideMenu: {
        home: this.$t('menu.home'),
        news: this.$t('menu.news'),
        myaccount: this.$t('menu.myaccount'),
        hospital: this.$t('menu.hospital'),
        contractPayment: this.$t('menu.contractPayment'),
        contract: this.$t('menu.contract'),
        billingAddress: this.$t('menu.billingAddress'),
        creditcard: this.$t('menu.creditcard'),
        billing: this.$t('menu.billing'),
        contactUs: this.$t('menu.contactUs'),
        contact: this.$t('menu.contact'),
        history: this.$t('menu.history'),
        webSite: this.$t('headerLink.webSite'),
        webStore: this.$t('headerLink.webStore'),
        officialSupporters: this.$t('menu.officialSupporters')
      },
      active: 'l-sideMenu_active'
    }
  },
  computed: {
    isContactMemberRoute() {
      return ['/mypage/contact', '/mypage/contact/confirm', '/mypage/contact/complete'].includes(this.$route.path)
    },
    ...mapGetters('common', ['getName', 'hasZuoraAccount', 'isOwner', 'env'])
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/foundation/base.scss';
@import '~assets/scss/mixins/pseudo.scss';

.l-sideMenu {
  display: block;
  list-style: none;
  padding: 24px 0;
  z-index: 11;

  &__list-item {
    margin-bottom: 1rem;

    &.--external {
      @include pseudo('external', color-text(body1));
    }

    a {
      color: color-text(body1);
      text-decoration: none;
      &:hover {
        color: color-text(gray);
      }
    }
  }
}

.nuxt-link-active {
  border-left: 3px solid color-brand(main, 1);
  color: color-text(body1);
}
.l-sideMenu_active {
  border-left: 3px solid color-brand(main, 1);
  color: color-text(body1);
}

.l-sideMenu_child {
  padding-left: 25px;
  a {
    border-left: none;
    display: block;
  }
  &_active {
    color: color-text(body1);
  }
}
</style>
