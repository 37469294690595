export const state = () => ({
  contractLovots: []
})

export const getters = {
  contractLovots: (state) => {
    return state.contractLovots
  }
}

export const mutations = {
  setContractLovots: (state, lovots) => {
    state.contractLovots = lovots
  },
  clear(state) {
    state.contractLovots = null
  }
}
