import { createVueI18n } from '~/service/buildObject.js'

export default function ({ app, store }) {
  // カルーセルのある画面からサイドメニューまたは、画面内のボタン等で別画面に遷移時、カルーセルのちらつきを防止
  const setCarouselVisible = 'common/setCarouselVisible'
  store.commit(setCarouselVisible, false)
  setTimeout(() => store.commit(setCarouselVisible, true), 0)

  if (!app.i18n) {
    app.i18n = createVueI18n()
  }
}
