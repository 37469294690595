<template>
  <div class="l-header">
    <div :class="overlayClass" />
    <div class="l-header__inner">
      <router-link :to="routePath" class="l-header__logo">
        <img src="~/assets/images/logos/lovot.svg" alt="LOVOT">
        <h1>お客様ポータルサイト</h1>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderGuest',
  data() {
    return {
      menuSpOpend: false
    }
  },
  computed: {
    overlayClass() {
      return {
        active: this.menuSpOpend,
        'l-overlay': true
      }
    },
    routePath() {
      let routePath = this.$route.path
      // 末尾に「/」があれば切り取る (e.g. /member/)
      if (routePath.substr(-1) === '/') {
        routePath = routePath.substr(0, routePath.length - 1)
      }
      if (['/member', '/member/confirm'].includes(routePath)) {
        return '/member'
      } else if (routePath === '/member/complete') {
        return '/mypage/lovot'
      } else if (['/contact', '/contact/confirm', '/contact/complete'].includes(routePath)) {
        return '/contact'
      } else {
        // 404
        return '/mypage/lovot'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/foundation/base.scss';

.l-header {
  width: 100%;
  color: color-text(gray);
  background-color: color-brand(white);
  box-shadow: 0 0 13px 0 rgba(187, 187, 187, 0.5);

  &__inner {
    height: $headerHeightSP;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
  }

  &__logo {
    display: flex;
    align-items: center;
    gap: 12px;
    img {
      width: 100px;
    }
    h1 {
      font-size: 12px;
      font-weight: bold;
    }
  }
  @include desktop() {
    &__inner {
      height: $headerHeightPC;
      justify-content: flex-start;
    }
    &__logo {
      img {
        width: 140px;
      }
      h1 {
        font-size: 16px;
      }
    }
  }
}

.l-overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 997;
  transition: opacity 0.6s ease;
  transition-delay: 0.3s;
  &.active {
    visibility: visible;
    opacity: 1;
  }
  @include desktop() {
    display: none;
  }
}
</style>
