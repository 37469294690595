/**
 * @description データをソートします。
 */

/**
 * @param {Object} colonies
 * 複数Colonyのghostsを表示ルールに合わせてソートします。
 * @return {Object} colonies
 */
export const sortColoniesGhosts = (colonies) => {
  if (colonies) {
    colonies.forEach((item) => {
      sortColonyGhosts(item)
    })
  }
}

/**
 * @param {Object} colony
 * 単一Colonyのghostsを表示ルールに合わせてソートします。
 * @return {Object} colony
 */
export const sortColonyGhosts = (colony) => {
  const maxNumber = 999
  if (colony) {
    colony.colony.ghosts.sort((g1, g2) => {
      // property, eye_positionが存在しない、またはeye_positionが数字で無い場合には最大値を設定
      let checkStrG1 = maxNumber
      let checkStrG2 = maxNumber
      if (g1.property && g1.property.eye_position && g1.property.eye_position <= maxNumber) checkStrG1 = g1.property.eye_position
      if (g2.property && g2.property.eye_position && g2.property.eye_position <= maxNumber) checkStrG2 = g2.property.eye_position
      // ネストは最後のため、最大値+1を設定
      if (g1.device_type !== 'lovot') checkStrG1 = 1000
      if (g2.device_type !== 'lovot') checkStrG2 = 1000
      return checkStrG1 - checkStrG2
    })
  }
}
