/**
 * pages/mypages配下の画面(会員の時に表示される画面)は
 * axiosではなくaxiosをラップしたhttpWrapperを使用すること。
 * (http#[get, post, put, delete]をラップしたイメージ)
 * このラッパーモジュールでは、画面リロードの際に以下の機能を担う。
 * 1. Vuexの内にユーザー情報がなければ、BEからユーザー情報を取得する
 * 2. 表示されている画面のAPIとユーザー情報取得APIとのローディングの順序を制御する
 *
 * @use this.$httpWrapper.[$get, $post, $put, $delete]
 * @see: https://github.com/axios/axios
 */

export default ({ app, store }, inject) => {
  inject('httpWrapper', {
    // リロード時、画面のAPIとsession APIの開始・終了のタイミングによっては
    // ローディングにちらつきが出てくるため、
    // get呼び出し時、sessionのレスポンスがstoreにない場合は
    // session -> 画面からのgetリクエスト順に同期処理を行う。
    async $get(url, config = null) {
      const name = store.getters['common/name']
      if (!name) {
        store.commit('common/clear')
        const user = await app.$axios.$get(`/session`)
        store.commit('common/setUser', user)
      }
      let response
      if (config) {
        response = await app.$axios.$get(url, config)
      } else {
        response = await app.$axios.$get(url)
      }
      return response
    },
    async $post(url, data = null, config = null) {
      let response
      if (data) {
        if (config) {
          response = await app.$axios.$post(url, data, config)
        } else {
          response = await app.$axios.$post(url, data)
        }
      } else if (config) {
        response = await app.$axios.$post(url, config)
      } else {
        response = await app.$axios.$post(url)
      }
      return response
    },
    async $put(url, data = null, config = null) {
      let response
      if (data) {
        if (config) {
          response = await app.$axios.$put(url, data, config)
        } else {
          response = await app.$axios.$put(url, data)
        }
      } else if (config) {
        response = await app.$axios.$put(url, config)
      } else {
        response = await app.$axios.$put(url)
      }
      return response
    },
    async $delete(url, config = null) {
      let response
      if (config) {
        response = await app.$axios.$delete(url, config)
      } else {
        response = await app.$axios.$delete(url)
      }
      return response
    }
  })
}
