import { TRANSITION_SLEEP_TIME } from '~/consts/common'

export default ({ store }, inject) => {
  inject('notify', {
    show(message) {
      store.commit('notify/visible', message)
      setTimeout(() => {
        store.commit('notify/invisible')
      }, TRANSITION_SLEEP_TIME)
    }
  })
}
