import VueI18n from 'vue-i18n'
import jaJP from '~/i18n/jaJp'

/**
 * @description データをもとにFE共通のオブジェクトを組み立てます。
 */

/**
 * @param {Object} now $moment
 * @param {Object} i18n $i18n
 * 直近1年から2019年までのセレクトボックス用リストを生成します。
 * @return {Object} { yearOptions, selectedDate }
 * yearOptions: [
 *   { label: '直近1年', yearLastDay: '20200610' },
 *   { label: '2019年', yearLastDay: '20191231' }
 * ],
 * selectedDate: 20200610
 */
export const createSelectboxYears = (now, i18n) => {
  const yearOptions = []
  const nowYear = now.year()
  const selectedDate = now.format('YYYYMMDD')
  // 発売年度の2019年からリストを作成。
  const yearLabel = i18n.t('common.year')
  for (let year = 2019; year <= nowYear; year++) {
    yearOptions.push({ label: `${year}${yearLabel}`, yearLastDay: `${year}1231` })
  }
  // リスト作成例:['直近１年', '2020年', '2019年']
  yearOptions.reverse()
  yearOptions.unshift({ label: i18n.t('common.lastYear'), yearLastDay: `${selectedDate}` })
  return { yearOptions, selectedDate }
}

/**
 * query(Object)からクエリパラメータ(String)を組み立て、URLを返却します。
 * 外部サイトからのクエリパラメータにはurl指定があるのでurlとそれ以外でクエリパラメータを生成します。
 * @param {Object} query
 * @return {String} クエリパラメータ付きのURL
 */

export const buildUrlWithQueryParams = (query) => {
  if (!query.url) return ''
  return decodeURIComponent(query.url)
}

/**
 * VueI18nインスタンスを生成します。
 * @return {VueI18n} 生成したインスタンス
 */
export const createVueI18n = () => {
  return new VueI18n({
    locale: 'ja',
    fallbackLocale: 'ja',
    messages: {
      'ja': jaJP
    }
  })
}
