export const state = () => ({
  selectedCase: null,
  access: false,
  apiError: null,
  contactForm: null
})

export const getters = {
  selectedCase: (state) => {
    return state.selectedCase
  },
  canAccess: (state) => {
    return state.access
  },
  contactForm: (state) => {
    return state.contactForm
  },
  apiError: (state) => {
    return state.apiError
  }
}

export const mutations = {
  setSelectedCase(state, selectedCase) {
    state.selectedCase = selectedCase
    state.access = true
  },
  setContactForm(state, contactForm) {
    state.contactForm = contactForm
    state.access = true
  },
  setAccessible(state, value = true) {
    state.access = value
  },
  setApiError(state, error) {
    state.apiError = error
  },
  clearContactForm(state) {
    state.contactForm = null
  },
  clear(state) {
    state.selectedCase = null
    state.access = false
    state.apiError = null
    state.contactForm = null
  }
}
