import Vue from 'vue'
import bugsnag from '@bugsnag/js'
import bugsnagVue from '@bugsnag/plugin-vue'

export default ({ store, app }) => {
  const env = store.getters['common/env']
  let bugsnagClient
  const hasApiKey = !!env.bugsnag_api_key
  if (hasApiKey) {
    bugsnagClient = bugsnag({
      apiKey: env.bugsnag_api_key,
      appType: 'frontend'
    })

    bugsnagClient.use(bugsnagVue, Vue)
    Vue.prototype.$bugsnagClient = bugsnagClient
  } else {
    // ローカル向けのダミーメソッド(notifyを呼び出すとエラーになるため)
    bugsnagClient = {
      notify: (e, options = null) => {
        console.warn(e, options)  // eslint-disable-line
      }
    }
    Vue.prototype.$bugsnagClient = bugsnagClient
  }
  app.$bugsnagClient = bugsnagClient

  // エラーハンドリングは不要かもしれないが暫く様子を見て調整する。
  // @see https://docs.bugsnag.com/platforms/javascript/vue/#reporting-unhandled-errors

  // @see
  // コンポーネントの描画関数とウォッチャにおいて未捕獲のエラー
  // https://jp.vuejs.org/v2/api/index.html#errorHandler
  Vue.config.errorHandler = (err, vm, info) => {
    store.commit('common/setLoading', false)
    if (hasApiKey) {
      // vmは有効なトレース情報がないし、vmとinfo両方だと1Mを超えるようでせっかく送った情報が削除される。
      bugsnagClient.notify(err, { metaData: { info } })
    } else {
      // ローカル検出用
      console.error('## global errorHandler ##', err, vm, info) // eslint-disable-line
    }
  }

  // @see
  // https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror
  window.addEventListener('error', (event) => {
    store.commit('common/setLoading', false)
    if (hasApiKey) {
      // エラーハンドリングしなくてもbugsnagにエラー情報が自動で送信されるのでここで送ると同様の内容が2回送信される。
      // onerror発生例：
      // <button onclick="foo()">存在しない関数を指定してクリック</button>

      // bugsnagClient.notify(new Error("onerror"), { metaData: { event } })
    } else {
      // ローカル検出用
      console.error('## global onerror ##', event) // eslint-disable-line
    }
  })

  // @see
  // https://developer.mozilla.org/en-US/docs/Web/Events/unhandledrejection
  window.addEventListener('unhandledrejection', (event) => {
    store.commit('common/setLoading', false)
    if (hasApiKey) {
      // エラーハンドリングしなくてもbugsnagにエラー情報が自動で送信されるのでここで送ると同様の内容が2回送信される。
      // unhandledrejection発生例：(createdなどの関数内に埋め込んで実行)
      // Promise.reject('Error at ' + new Date().toLocaleTimeString())

      // bugsnagClient.notify(new Error("unhandledrejection"), { metaData: { event } })
    } else {
      // ローカル検出用
      console.error('## global unhandledrejection ##', event) // eslint-disable-line
    }
    event.preventDefault()
  })
}
