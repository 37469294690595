export const state = () => ({
  hospitals: [],
  detail: null
})

export const getters = {
  hospitals: (state) => {
    return state.hospitals
  },
  hospitalDetail: (state) => {
    return state.detail
  }
}

export const mutations = {
  setHospitals(state, hospitals) {
    state.hospitals = hospitals
  },
  setHospitalDetail(state, { receiptNumber, colonyId }) {
    state.detail = { receiptNumber, colonyId }
  },
  clear(state) {
    state.hospitals = null
  }
}
