export const state = () => ({
  form: null,
  apiError: null
})

export const getters = {
  memberForm: (state) => {
    return state.form
  },
  apiError: (state) => {
    return state.apiError
  }
}

export const mutations = {
  setMemberForm(state, form) {
    state.form = form
  },
  setApiError(state, error) {
    state.apiError = error
  },
  clear(state) {
    state.form = null
    state.apiError = null
  }
}
