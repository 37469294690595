export const state = () => ({
  env: getEnv(), // eslint-disable-line
  user: {
    firstName: null,
    lastName: null,
    hasZuoraAccount: false,
    hasGmoId: false,
    isOwner: false,
    isInHouse: false
  },
  loading: false,
  carouselVisible: true,
  isIE: null
})

export const getters = {
  env: (state) => {
    return state.env
  },
  name: (state) => {
    if (!state.user.lastName) {
      return ''
    } else if (state.user.firstName) {
      return (state.user.lastName + state.user.firstName)
    } else {
      return state.user.lastName
    }
  },
  hasZuoraAccount: (state) => {
    return state.user.hasZuoraAccount
  },
  hasGmoId: (state) => {
    return state.user.hasGmoId
  },
  isOwner: (state) => {
    return state.user.isOwner
  },
  isInHouse: (state) => {
    return state.user.isInHouse
  },
  isLoading: (state) => {
    return state.loading
  },
  carouselVisible: (state) => {
    return state.carouselVisible
  },
  isIE: (state) => {
    return state.isIE
  }

}

export const mutations = {
  setUser(state, user) {
    state.user = user
  },
  setName(state, name) {
    state.user.firstName = name.firstName
    state.user.lastName = name.lastName
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setCarouselVisible(state, visible) {
    state.carouselVisible = visible
  },
  clear(state) {
    state.form = null
    state.user.firstName = null
    state.user.lastName = null
    state.user.hasZuoraAccount = false
    state.user.hasGmoId = false
    state.user.isOwner = false
    state.carouselVisible = true
  },
  setIE(state, ie) {
    state.isIE = ie
  }
}
