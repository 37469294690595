import { LOADING_END_SLEEP_TIME } from '~/consts/common'

// @see https://axios.nuxtjs.org/extend
export default function ({ $axios, redirect, store, route, app }) {
  $axios.onRequest((config) => {
    store.commit('common/setLoading', true)
    config.headers.common['X-Gxp-Secret'] = 'X-Gxp-Secret' // リクエスト時に顧客ポータルカスタムヘッダを設定。
  })
  $axios.onResponse((config) => {
    handleLoading(store)
  })
  $axios.onError((error) => {
    handleLoading(store)

    let apiUrl = null
    const { response, config } = error
    if (response || config) {
      if (response.config) {
        apiUrl = response.config.url
      } else if (config) {
        apiUrl = config.url
      }
    }
    if (!apiUrl) {
      // apiUrlが取得できないケースが不明なのでログに出して内容が確認できるようにしておく
      app.$bugsnagClient.notify(error, {
        metaData: {
          request: error.request,
          response: error.response,
          config: error.config
        }
      })
      return
    }

    // ファイルアップロード・ダウンロードはamazonのS3と直接ファイルのやり取りをするため
    // 共通APIエラーハンドリングはせず、呼び出し元にそのまま返す。
    if (apiUrl && apiUrl.indexOf('amazonaws.com') >= 0) {
      return
    }

    let canErrorHandle = true
    // 新規会員登録、メールでのお問い合わせは404, 503以外エラーハンドリングしない
    if (['member', 'contact'].some(name => name === app.router.currentRoute.name)) {
      canErrorHandle = false
    }

    const code = parseInt(error.response && error.response.status)
    if (code >= 400 && code < 500) {
      if (canErrorHandle && code === 401) {
        // ログイン画面に遷移
        const env = store.getters['common/env']
        const encodeUrl = encodeURIComponent(window.location.href)
        window.location.href = `${env.gx_portal_login_url}?url=${encodeUrl}`
      } else if (canErrorHandle && code === 403) {
        // 新規会員登録画面に遷移
        if (route.path === '/mypage/contact') {
          // ルートパスが新規お問い合わせ画面の場合(非会員のお問い合わせ画面から遷移してきてアカウントが未登録)
          // 新規会員登録画面で登録完了後、新規お問い合わせ画面にリダイレクトさせる。
          const { protocol, host } = window.location
          const url = `${protocol}//${host}${route.path}`
          redirect({ name: 'member', query: { url } })
        } else {
          redirect({ name: 'member' })
        }
      } else if (code === 404) {
        redirect({ name: 'notfound' })
      }
    } else if (code === 503) {
      redirect({ name: 'error' })
    }
  })
}

const handleLoading = (store) => {
  const sleepPromise = ms => new Promise(resolve => setTimeout(resolve, ms))
  // 非同期 sleepしている間、新しいAPIが実行されるとローディング画面は表示し続ける。
  sleepPromise(LOADING_END_SLEEP_TIME).then(() => {
    store.commit('common/setLoading', false)
  })
}
