// 画面遷移までのスリープタイム
export const TRANSITION_SLEEP_TIME = 2000
// ファイル拡張子ホワイトリスト
export const FILE_EXTENSIONS = [
  'jpeg',
  'jpg',
  'png',
  'bmp',
  'heic',
  'mp4',
  'mpeg',
  'mpg',
  'flv',
  'wmv',
  'mov',
  'wav',
  'mp3',
  'wma',
  'm4a',
  'pdf'
]
// ローディング終了までのスリープタイム(30min)
export const LOADING_END_SLEEP_TIME = 1800000
// URL正規表現
export const URL_REGEX = /(https?:\/\/[-_.!~*¥'()a-zA-Z0-9;/?:¥@&=+¥$,%#]+)/
export const PROTOCOL_FQDN_REGEX = /^(https?:\/\/[^/?]+|lovot:\/\/+)/
