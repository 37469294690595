export const state = () => ({
  lovots: [],
  detailVisible: false
})

export const getters = {
  lovots: (state) => {
    return state.lovots
  },
  detailVisible: (state) => {
    return state.detailVisible
  }
}

export const mutations = {
  setLovots(state, lovots) {
    state.lovots = lovots
  },
  toggleDetailVisible(state) {
    state.detailVisible = !state.detailVisible
  },
  clear(state) {
    state.lovots = null
  }
}
