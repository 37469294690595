// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/header/pc_header_link_icon.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".l-sideMenu[data-v-4febb98e]{display:block;list-style:none;padding:24px 0;z-index:11}.l-sideMenu__list-item[data-v-4febb98e]{margin-bottom:1rem}.l-sideMenu__list-item.--external[data-v-4febb98e]{align-items:center;display:flex}.l-sideMenu__list-item.--external[data-v-4febb98e]:after{background-color:#4d4d4d;content:\"\";display:inline-block;height:1.2rem;margin-left:4px;-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");-webkit-mask-repeat:no-repeat;mask-repeat:no-repeat;-webkit-mask-size:cover;mask-size:cover;width:1.2rem}.l-sideMenu__list-item a[data-v-4febb98e]{color:#4d4d4d;-webkit-text-decoration:none;text-decoration:none}.l-sideMenu__list-item a[data-v-4febb98e]:hover{color:#8f8f8f}.l-sideMenu_active[data-v-4febb98e],.nuxt-link-active[data-v-4febb98e]{border-left:3px solid #532a13;color:#4d4d4d}.l-sideMenu_child[data-v-4febb98e]{padding-left:25px}.l-sideMenu_child a[data-v-4febb98e]{border-left:none;display:block}.l-sideMenu_child_active[data-v-4febb98e]{color:#4d4d4d}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
