import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _16a06730 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _212bb190 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _3be7779d = () => interopDefault(import('../pages/member/index.vue' /* webpackChunkName: "pages/member/index" */))
const _3ec77941 = () => interopDefault(import('../pages/member/complete/index.vue' /* webpackChunkName: "pages/member/complete/index" */))
const _42a05fe4 = () => interopDefault(import('../pages/member/confirm/index.vue' /* webpackChunkName: "pages/member/confirm/index" */))
const _1405becf = () => interopDefault(import('../pages/mypage/contact/index.vue' /* webpackChunkName: "pages/mypage/contact/index" */))
const _a49f3ece = () => interopDefault(import('../pages/mypage/contract/index.vue' /* webpackChunkName: "pages/mypage/contract/index" */))
const _dd4c087e = () => interopDefault(import('../pages/mypage/hospital/index.vue' /* webpackChunkName: "pages/mypage/hospital/index" */))
const _00e70a27 = () => interopDefault(import('../pages/mypage/lovot/index.vue' /* webpackChunkName: "pages/mypage/lovot/index" */))
const _0c62b920 = () => interopDefault(import('../pages/mypage/myaccount/index.vue' /* webpackChunkName: "pages/mypage/myaccount/index" */))
const _b0a299cc = () => interopDefault(import('../pages/mypage/news/index.vue' /* webpackChunkName: "pages/mypage/news/index" */))
const _fa41e670 = () => interopDefault(import('../pages/mypage/official-supporters/index.vue' /* webpackChunkName: "pages/mypage/official-supporters/index" */))
const _85bdab62 = () => interopDefault(import('../pages/mypage/contact/complete/index.vue' /* webpackChunkName: "pages/mypage/contact/complete/index" */))
const _ae167580 = () => interopDefault(import('../pages/mypage/contact/confirm/index.vue' /* webpackChunkName: "pages/mypage/contact/confirm/index" */))
const _bfe0ebd8 = () => interopDefault(import('../pages/mypage/contact/history/index.vue' /* webpackChunkName: "pages/mypage/contact/history/index" */))
const _61b20b05 = () => interopDefault(import('../pages/mypage/contract/billing/index.vue' /* webpackChunkName: "pages/mypage/contract/billing/index" */))
const _ecc8b50c = () => interopDefault(import('../pages/mypage/contract/billing_address/index.vue' /* webpackChunkName: "pages/mypage/contract/billing_address/index" */))
const _f8aaa056 = () => interopDefault(import('../pages/mypage/contract/creditcard/index.vue' /* webpackChunkName: "pages/mypage/contract/creditcard/index" */))
const _80565616 = () => interopDefault(import('../pages/mypage/hospital/detail/index.vue' /* webpackChunkName: "pages/mypage/hospital/detail/index" */))
const _b6420644 = () => interopDefault(import('../pages/mypage/lovot/owners/index.vue' /* webpackChunkName: "pages/mypage/lovot/owners/index" */))
const _51145b72 = () => interopDefault(import('../pages/mypage/lovot/update/index.vue' /* webpackChunkName: "pages/mypage/lovot/update/index" */))
const _10bce9f6 = () => interopDefault(import('../pages/mypage/myaccount/change/index.vue' /* webpackChunkName: "pages/mypage/myaccount/change/index" */))
const _78a378e1 = () => interopDefault(import('../pages/mypage/myaccount/confirm/index.vue' /* webpackChunkName: "pages/mypage/myaccount/confirm/index" */))
const _40651342 = () => interopDefault(import('../pages/mypage/contact/history/detail/index.vue' /* webpackChunkName: "pages/mypage/contact/history/detail/index" */))
const _485a09bb = () => interopDefault(import('../pages/mypage/contract/billing_address/change/index.vue' /* webpackChunkName: "pages/mypage/contract/billing_address/change/index" */))
const _3efad0eb = () => interopDefault(import('../pages/mypage/contract/billing_address/confirm/index.vue' /* webpackChunkName: "pages/mypage/contract/billing_address/confirm/index" */))
const _25edd131 = () => interopDefault(import('../pages/mypage/contract/billing/detail/index.vue' /* webpackChunkName: "pages/mypage/contract/billing/detail/index" */))
const _6836b300 = () => interopDefault(import('../pages/mypage/contract/creditcard/change/index.vue' /* webpackChunkName: "pages/mypage/contract/creditcard/change/index" */))
const _1ab35046 = () => interopDefault(import('../pages/mypage/contract/creditcard/confirm/index.vue' /* webpackChunkName: "pages/mypage/contract/creditcard/confirm/index" */))
const _3c83b188 = () => interopDefault(import('../pages/mypage/contact/history/detail/complete/index.vue' /* webpackChunkName: "pages/mypage/contact/history/detail/complete/index" */))
const _157df6b3 = () => interopDefault(import('../pages/mypage/contact/history/detail/confirm/index.vue' /* webpackChunkName: "pages/mypage/contact/history/detail/confirm/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _16a06730,
    name: "404"
  }, {
    path: "/error",
    component: _212bb190,
    name: "error"
  }, {
    path: "/member",
    component: _3be7779d,
    name: "member"
  }, {
    path: "/member/complete",
    component: _3ec77941,
    name: "member-complete"
  }, {
    path: "/member/confirm",
    component: _42a05fe4,
    name: "member-confirm"
  }, {
    path: "/mypage/contact",
    component: _1405becf,
    name: "mypage-contact"
  }, {
    path: "/mypage/contract",
    component: _a49f3ece,
    name: "mypage-contract"
  }, {
    path: "/mypage/hospital",
    component: _dd4c087e,
    name: "mypage-hospital"
  }, {
    path: "/mypage/lovot",
    component: _00e70a27,
    name: "mypage-lovot"
  }, {
    path: "/mypage/myaccount",
    component: _0c62b920,
    name: "mypage-myaccount"
  }, {
    path: "/mypage/news",
    component: _b0a299cc,
    name: "mypage-news"
  }, {
    path: "/mypage/official-supporters",
    component: _fa41e670,
    name: "mypage-official-supporters"
  }, {
    path: "/mypage/contact/complete",
    component: _85bdab62,
    name: "mypage-contact-complete"
  }, {
    path: "/mypage/contact/confirm",
    component: _ae167580,
    name: "mypage-contact-confirm"
  }, {
    path: "/mypage/contact/history",
    component: _bfe0ebd8,
    name: "mypage-contact-history"
  }, {
    path: "/mypage/contract/billing",
    component: _61b20b05,
    name: "mypage-contract-billing"
  }, {
    path: "/mypage/contract/billing_address",
    component: _ecc8b50c,
    name: "mypage-contract-billing_address"
  }, {
    path: "/mypage/contract/creditcard",
    component: _f8aaa056,
    name: "mypage-contract-creditcard"
  }, {
    path: "/mypage/hospital/detail",
    component: _80565616,
    name: "mypage-hospital-detail"
  }, {
    path: "/mypage/lovot/owners",
    component: _b6420644,
    name: "mypage-lovot-owners"
  }, {
    path: "/mypage/lovot/update",
    component: _51145b72,
    name: "mypage-lovot-update"
  }, {
    path: "/mypage/myaccount/change",
    component: _10bce9f6,
    name: "mypage-myaccount-change"
  }, {
    path: "/mypage/myaccount/confirm",
    component: _78a378e1,
    name: "mypage-myaccount-confirm"
  }, {
    path: "/mypage/contact/history/detail",
    component: _40651342,
    name: "mypage-contact-history-detail"
  }, {
    path: "/mypage/contract/billing_address/change",
    component: _485a09bb,
    name: "mypage-contract-billing_address-change"
  }, {
    path: "/mypage/contract/billing_address/confirm",
    component: _3efad0eb,
    name: "mypage-contract-billing_address-confirm"
  }, {
    path: "/mypage/contract/billing/detail",
    component: _25edd131,
    name: "mypage-contract-billing-detail"
  }, {
    path: "/mypage/contract/creditcard/change",
    component: _6836b300,
    name: "mypage-contract-creditcard-change"
  }, {
    path: "/mypage/contract/creditcard/confirm",
    component: _1ab35046,
    name: "mypage-contract-creditcard-confirm"
  }, {
    path: "/mypage/contact/history/detail/complete",
    component: _3c83b188,
    name: "mypage-contact-history-detail-complete"
  }, {
    path: "/mypage/contact/history/detail/confirm",
    component: _157df6b3,
    name: "mypage-contact-history-detail-confirm"
  }, {
    path: "*",
    component: _16a06730,
    name: "notfound"
  }, {
    path: "/",
    component: _00e70a27,
    name: "lovot"
  }, {
    path: "/license",
    beforeEnter: (to, from, next) => {
          // Put the full page url including the protocol http(s) below
          window.location = 'https://lovot.life/terms/license/'
        }
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
