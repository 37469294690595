export const state = () => ({
  form: {
    holderName: null,
    cardNo: null,
    expiredMonth: null,
    expiredYear: null,
    scode: null,
    fastErrFlg: null, // 情報表示の取得エラーフラグ
    transitionFormer: null // 遷移元
  },
  memberId: null, // Bugsnagに設定する情報
  apiError: null,
  gmoErrorMsgs: []
})

export const getters = {
  creditcardForm: (state) => {
    return state.form
  },
  memberId: (state) => {
    return state.memberId
  },
  apiError: (state) => {
    return state.apiError
  },
  gmoError: (state) => {
    return state.gmoErrorMsgs
  }
}

export const mutations = {
  setCreditcardForm(state, form) {
    state.form = form
  },
  setTransInfo(state) {
    state.form.transitionFormer = 'info'
  },
  setTransConfirm(state) {
    state.form.transitionFormer = 'confirm'
  },
  setMemberId(state, memberId) {
    state.memberId = memberId
  },
  setApiError(state, error) {
    state.apiError = error
  },
  addGmoError(state, error) {
    state.gmoErrorMsgs.push(error)
  },
  clearGmoError(state) {
    state.gmoErrorMsgs = []
  },
  clear(state) {
    state.form = null
    state.memberId = null
    state.apiError = null
    state.gmoErrorMsgs = []
  }
}
