export const state = () => ({
  form: null,
  apiError: null,
  access: false
})

export const getters = {
  billingAddressForm: (state) => {
    return state.form
  },
  canAccess: (state) => {
    return state.access
  },
  apiError: (state) => {
    return state.apiError
  }
}

export const mutations = {
  setBillingAddressForm(state, form) {
    state.form = form
    state.apiError = null
  },
  setAccessible(state) {
    state.access = true
  },
  setApiError(state, error) {
    state.apiError = error
  },
  clear(state) {
    state.form = null
    state.apiError = null
    state.access = false
  }
}
