<template>
  <div class="l-page">
    <l-header />
    <div class="l-container">
      <nuxt />
    </div>
    <l-footer />
    <transition name="fade">
      <app-loading v-if="isLoading" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import lFooter from '~/components/layouts/footer.vue'
import lHeader from '~/components/layouts/header.vue'
import AppLoading from '~/components/object/navi/Loading.vue'

export default {
  name: 'SingleLayout',
  components: {
    lFooter,
    lHeader,
    AppLoading
  },
  computed: {
    ...mapGetters('common', ['isLoading'])
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/foundation/base.scss';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.l-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  word-break: break-all;
}

.l-container {
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px 100px 15px;
  max-width: 640px;
  @include desktop() {
    max-width: 800px;
    padding: 0 0 200px 0;
  }
}
</style>
