<template>
  <nav :class="['l-drawer', { '--active': open }]">
    <div class="l-drawer__account">
      <div class="l-drawer__account-user">
        <i class="fas fa-user-circle" />
        <span>{{ name }} 様</span>
      </div>
      <div class="l-drawer__account-btn-wrapper">
        <a href="/mypage/myaccount" class="c-button-tiny">
          {{ badgeMenu.myaccount }}
        </a>
        <a :href="env.gx_portal_logout_url" class="c-button-tiny --reverse">
          {{ headerLink.logout }}
        </a>
      </div>
    </div>
    <div class="l-drawer__block">
      <ul v-if="isOwner" class="l-drawer__list">
        <li class="l-drawer__list-item --external">
          <a href="https://help.lovot.life/category/information/" target="_blank" class="u-gray py-2">
            {{ badgeMenu.news }}
          </a>
        </li>
        <li class="l-drawer__list-item">
          <a href="/mypage/hospital" class="u-gray py-2">
            {{ badgeMenu.hospital }}
          </a>
        </li>
      </ul>
    </div>
    <div v-if="hasZuoraAccount" class="l-drawer__block">
      <div class="c-subheader">
        {{ badgeMenu.contractPayment }}
      </div>
      <ul class="l-drawer__list">
        <li class="l-drawer__list-item">
          <a href="/mypage/contract" class="u-gray py-2">
            {{ badgeMenu.contract }}
          </a>
        </li>
        <li class="l-drawer__list-item">
          <a href="/mypage/contract/billing_address" class="u-gray py-2">
            {{ badgeMenu.billingAddress }}
          </a>
        </li>
        <li class="l-drawer__list-item">
          <a href="/mypage/contract/creditcard" class="u-gray py-2">
            {{ badgeMenu.creditcard }}
          </a>
        </li>
        <li class="l-drawer__list-item">
          <a href="/mypage/contract/billing" class="u-gray py-2">
            {{ badgeMenu.billing }}
          </a>
        </li>
      </ul>
    </div>
    <div class="l-drawer__block">
      <div class="c-subheader">
        {{ badgeMenu.contactUs }}
      </div>
      <ul class="l-drawer__list">
        <li class="l-drawer__list-item">
          <a href="/mypage/contact" class="u-gray py-2">
            {{ badgeMenu.contact }}
          </a>
        </li>
        <li class="l-drawer__list-item">
          <a href="/mypage/contact/history" class="u-gray py-2">
            {{ badgeMenu.history }}
          </a>
        </li>
      </ul>
    </div>
    <div class="l-drawer__block --bordered">
      <ul class="l-drawer__list">
        <div v-if="isOwner">
          <li class="l-drawer__list-item">
            <a href="/mypage/official-supporters">
              {{ badgeMenu.officialSupporters }}
            </a>
          </li>
        </div>
        <li class="l-drawer__list-item --external">
          <a href="https://lovot.life/" target="_blank">
            {{ headerLink.webSite }}
          </a>
        </li>
        <li class="l-drawer__list-item --external">
          <a :href="env.gx_ec_portal_url" target="_blank">
            {{ headerLink.webStore }}
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    badgeMenu: {
      type: Object,
      required: true
    },
    headerLink: {
      type: Object,
      required: true
    },
    open: {
      type: Boolean,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    isOwner: {
      type: Boolean,
      required: true
    },
    hasZuoraAccount: {
      type: Boolean,
      required: true
    },
    env: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/mixins/pseudo.scss';
@import '~assets/scss/foundation/base.scss';

.l-drawer {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-100vh);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: color-brand(white);
  padding: $headerHeightSP 16px 24px;
  z-index: 998;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 24px;
  transition-property: transform, opacity;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);

  & > * {
    opacity: 0;
    transform: translateY(-10px);
  }

  &.--active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);

    & > * {
      @for $i from 1 through 6 {
        &:nth-child(#{$i}) {
          animation: listanim 0.5s ease #{(0.5 + 0.1 * ($i - 1))}s forwards;
        }
      }
    }
  }
  @include desktop() {
    display: none;
  }

  a {
    text-decoration: none;
  }

  &__account {
    padding-top: 8px;
    color: color-text(body2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    &-btn-wrapper {
      display: flex;
      gap: 16px;
    }

    &-user {
      display: flex;
      align-items: center;
      gap: 8px;

      i {
        font-size: 24px;
      }
      span {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  &__block {
    &.--bordered {
      padding-top: 16px;
      border-top: 1px solid color-border(normal);
    }
    .c-subheader {
      margin-bottom: 12px;
    }
  }

  &__list {
    padding: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-item {
      list-style: none;
      &.--external {
        @include pseudo('external', color-text(body1));
      }
    }
  }

  @keyframes listanim {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
