export default (i18nObjectName = {}) => {
  return {
    data() {
      return {
        mx_errorMessages: []
      }
    },
    methods: {
      mx_addErrors(error) {
        this.mx_clearErrors()
        if (!error.response) {
          this.mx_errorMessages.push(this.$t('validations.interNetDisconnect'))
          return
        }
        const { status } = error.response

        let message
        if (error.message) {
          message = error.message
        } else if (status === 400) {
          message = this.$t('validations.badRequest')
        } else if (status === 401) {
          message = this.$t('validations.notLogin')
        } else if (status === 403) {
          message = this.$t('validations.forbidden')
        } else if (status === 412) {
          message = this.$t('validations.preconditionFailed ')
        } else {
          message = this.$t('error.systemError')
        }
        this.mx_errorMessages.push(message)
      },
      mx_addErrorMessages(messages) {
        this.mx_clearErrors()
        this.mx_errorMessages = messages
      },
      mx_clearErrors() {
        this.mx_errorMessages = []
      }
    }
  }
}
