<template>
  <div class="l-header">
    <HeaderDrawer
      :badge-menu="badgeMenu"
      :header-link="headerLink"
      :open="menuSpOpend"
      :name="name"
      :is-owner="isOwner"
      :has-zuora-account="hasZuoraAccount"
      :env="env"
    />
    <!-- SPメニュー -->
    <div :class="['l-header__sp', { '--no-shadow': menuSpOpend}]">
      <a :class="hamburgerClass" tabindex="0" @click="userMenuSp" @keyup.enter="userMenuSp">
        <span class="l-hamburger_line" />
        <span class="l-hamburger_line" />
        <span class="l-hamburger_line" />
      </a>
      <router-link to="/mypage/lovot" class="l-header__logo">
        <img src="~/assets/images/logos/lovot.svg" alt="LOVOT">
        <h1>お客様ポータルサイト</h1>
      </router-link>
    </div>
    <!-- PCメニュー -->
    <div class="l-header__pc">
      <router-link to="/mypage/lovot" class="l-header__logo">
        <img src="~/assets/images/logos/lovot.svg" alt="LOVOT">
        <h1>お客様ポータルサイト</h1>
      </router-link>
      <div class="l-header__pc-menu">
        <div v-if="name" class="l-header__pc-menu-item" tabindex="0" @click="userMenu" @keyup.enter="userMenu">
          <a :class="parentClass">
            <i class="fas fa-user-circle mr-2 l-userName_icon" />
            <span class="l-userName_content">
              {{ calculatedUserName }}様
            </span>
          </a>
          <div :class="menuClass">
            <a href="/mypage/myaccount">
              {{ badgeMenu.myaccount }}
            </a>
            <a :href="env.gx_portal_logout_url">
              {{ headerLink.logout }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ErrorMessageHandleable from '~/mixins/errorMessageHandleable'
import HeaderDrawer from '~/components/layouts/headerDrawer'

export default {
  name: 'Header',
  components: {
    HeaderDrawer
  },
  mixins: [ErrorMessageHandleable()],
  data() {
    return {
      menuOpend: false,
      menuSpOpend: false,
      badgeMenu: {
        home: this.$t('menu.home'),
        news: this.$t('menu.news'),
        newsLong: this.$t('menu.newsLong'),
        myaccount: this.$t('menu.myaccount'),
        hospital: this.$t('menu.hospital'),
        contractPayment: this.$t('menu.contractPayment'),
        contract: this.$t('menu.contract'),
        billingAddress: this.$t('menu.billingAddress'),
        creditcard: this.$t('menu.creditcard'),
        billing: this.$t('menu.billing'),
        contactUs: this.$t('menu.contactUs'),
        contact: this.$t('menu.contact'),
        history: this.$t('menu.history'),
        officialSupporters: this.$t('menu.officialSupporters')
      },
      headerLink: {
        webSite: this.$t('headerLink.webSite'),
        webStore: this.$t('headerLink.webStore'),
        logout: this.$t('headerLink.logout')
      }
    }
  },
  computed: {
    menuClass() {
      return {
        active: this.menuOpend,
        'l-headerMenu_child': true
      }
    },
    parentClass() {
      return {
        active: this.menuOpend,
        'l-headerMenu_parent': true
      }
    },
    hamburgerClass() {
      return {
        active: this.menuSpOpend,
        'l-hamburger': true
      }
    },
    calculatedUserName() {
      let name = this.get20charsName(this.name)
      if (name !== this.name) {
        name = name + '...'
      }
      return name
    },
    ...mapGetters('common', ['name', 'hasZuoraAccount', 'isOwner', 'isLoading', 'env'])
  },
  methods: {
    userMenu() {
      this.menuOpend = !this.menuOpend
    },
    userMenuSp() {
      this.menuSpOpend = !this.menuSpOpend
      this.$nextTick(() => this.$refs.badgeMenuHome.focus())
    },
    get20charsName(str) {
      let result = 0
      let chars = ''
      for (let i = 0; i < str.length; i++) {
        const chr = str.charCodeAt(i)
        if (
          (chr >= 0x00 && chr < 0x81) ||
          chr === 0xf8f0 ||
          (chr >= 0xff61 && chr < 0xffa0) ||
          (chr >= 0xf8f1 && chr < 0xf8f4)
        ) {
          result += 1
        } else {
          result += 2
        }
        if (result > 20) {
          return chars
        }
        chars = chars + '' + str[i]
      }
      return chars
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/foundation/base.scss';

.l-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  color: color-text(gray);
  background-color: color-brand(white);
  box-shadow: 0 0 13px 0 rgba(187, 187, 187, 0.5);
  &__logo {
    display: flex;
    align-items: center;
    gap: 12px;

    &:hover {
      text-decoration: none;
    }

    img {
      width: 100px;
    }
    h1 {
      font-size: 12px;
      font-weight: bold;
    }

    @include desktop() {
      img {
        width: 140px;
      }
      h1 {
        font-size: 16px;
      }
    }
  }

  &__pc {
    display: none;

    @include desktop() {
      height: $headerHeightPC;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1024px;
      margin: 0 auto;
    }

    &-menu {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &-item {
        padding: 0 16px;
      }
    }
  }

  &__icon {
    &::after {
      background-image: url('~assets/images/header/pc_header_link_icon.svg');
      display: inline-block;
      background-size: 14px 14px;
      width: 14px;
      height: 14px;
      content:"";
      vertical-align: middle;
      margin-left: 7px;
      @media all and (-ms-high-contrast: none) {
        vertical-align: top;
      }
    }
  }

  &__sp {
    position: relative;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $headerHeightSP;
    background-color: color-brand(white);
    box-shadow: 0 0 13px 0 rgba(187, 187, 187, 0.5);
    padding: 0 16px;
    transition: box-shadow 0.3s;

    &.--no-shadow {
      box-shadow: none;
    }

    @include desktop() {
      display: none;
    }
  }
}

.l-hamburger {
  display: block;
  position: absolute;
  left: 16px;
  width: 18px;
  height: 16px;
  cursor: pointer;

  &_line {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: color-text(body1);
    transition: transform 0.3s;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 7px;
    }

    &:nth-child(3) {
      bottom: 0;
    }
  }

  &.active {
    .l-hamburger_line {
      &:nth-child(1) {
        top: 7px;
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        top: 7px;
        transform: scale(0);
      }

      &:nth-child(3) {
        bottom: 7px;
        transform: rotate(-45deg);
      }
    }
  }
}

.l-userName_icon {
  font-size: 24px;
  vertical-align: middle;
}
.l-userName_spblock {
  width: 90vw;
  display: flex;
  align-items: center;
}
.l-userName_spcontent {
  display: inline-block;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: 0.55px;
}
.l-userName_content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: -2px;
  vertical-align: middle;
  text-align: right;
  @media all and (-ms-high-contrast: none) {
    padding-top: 5px;
  }
}

.l-headerMenu_parent {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  &::after {
    content: url('~assets/images/header/pc_header_arrow_1.png');
    display: inline-block;
    margin-left: 7px;
    transform: rotate(180deg);
  }
  &.active {
    &::after {
      transform: none;
    }
  }
}

.l-headerMenu_child {
  display: none;
  z-index: 10000;
  position: absolute;
  top: calc($headerHeightPC - 8px);
  background-color: color-brand(white);
  border: 1px solid color-bg(light);
  a {
    display: block;
    padding: 12px 20px;
  }
  &.active {
    display: block;
  }
}
</style>
