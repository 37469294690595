export const state = () => ({
  form: null,
  apiError: null,
  access: false
})

export const getters = {
  contactForm: (state) => {
    return state.form
  },
  canAccess: (state) => {
    return state.access
  },
  apiError: (state) => {
    return state.apiError
  }
}

export const mutations = {
  setContactForm(state, form) {
    state.form = form
    state.apiError = null
  },
  setAccessible(state, value = true) {
    state.access = value
  },
  setApiError(state, error) {
    state.apiError = error
  },
  clear(state) {
    state.form = null
    state.apiError = null
    state.access = false
  }
}
